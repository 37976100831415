import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'

const routes: Routes = [
  { path: '', redirectTo: '/gis-info', pathMatch: 'full' },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./components/dashboard/dashboard.module').then(
        (m) => m.DashboardModule,
      ),
  },
  {
    path: 'device',
    loadChildren: () =>
      import('./components/device/device.module').then((m) => m.DeviceModule),
  },

  {
    path: 'settings',
    loadChildren: () =>
      import('./components/settings/settings.module').then(
        (m) => m.SettingsModule,
      ),
  },
  {
    path: 'data-report',
    loadChildren: () =>
      import('./components/data-report/data-report.module').then(
        (m) => m.DataReportModule,
      ),
  },
  {
    path: 'meter-data',
    loadChildren: () =>
      import('./components/meter-data/meter-data.module').then(
        (m) => m.MeterDataModule,
      ),
  },

  {
    path: 'analytics',
    loadChildren: () =>
      import('./components/analytics/analytics.module').then(
        (m) => m.AnalyticsModule,
      ),
  },
  {
    path: 'setting-history',
    loadChildren: () =>
      import('./components/setting-history/setting-history.module').then(
        (m) => m.SettingHistoryModule,
      ),
  },
  {
    path: 'gis-info',
    loadChildren: () =>
      import('./components/gis-info/gis-info.module').then(
        (m) => m.GisInfoModule,
      ),
  },

  {
    path: 'system-battery',
    loadChildren: () =>
      import('./components/system-battery/system-battery.module').then(
        (m) => m.SystemBatteryModule,
      ),
  },

  {
    path: 'software',
    loadChildren: () =>
      import('./components/software/software.module').then(
        (m) => m.SoftwareModule,
      ),
  },

  {
    path: 'office-setting',
    loadChildren: () =>
      import('./components/office-setting/office-setting.module').then(
        (m) => m.OfficeSettingModule,
      ),
  },

  {
    path: 'dashboard-dtr',
    loadChildren: () =>
      import('./components/dashboard-dtr/dashboard-dtr.module').then(
        (m) => m.DashboardDtrModule,
      ),
    data: { isDTR: true },
  },

  {
    path: 'dashboard-ptr',
    loadChildren: () =>
      import('./components/dashboard-dtr/dashboard-dtr.module').then(
        (m) => m.DashboardDtrModule,
      ),
    data: { isDTR: false },
  },

  {
    path: 'account-list',
    loadChildren: () =>
      import('./components/account/account.module').then(
        (m) => m.AccountModule,
      ),
  },

  {
    path: 'ss-report',
    loadChildren: () =>
      import('./components/ss-report/ssptrr.module').then(
        (m) => m.SsptrrModule,
      ),
  },

  {
    path: 'sslddashboard',
    loadChildren: () =>
      import('./components/sslddashboard/ss-sdl.module').then(
        (m) => m.SSLDDashboardModule,
      ),
  },

  {
    path: 'roles',
    loadChildren: () =>
      import('./components/role/role.module').then((m) => m.RoleModule),
  },

  {
    path: 'dtr',
    loadChildren: () =>
      import(
        './components/dtr-prediction-analysis/dtr-prediction-analysis.module'
      ).then((m) => m.DtrPredictionAnalysisModule),
  },

  {
    path: 'page-permission',
    loadChildren: () =>
      import(
        './components/page-permission/page-permission-routing.module'
      ).then((m) => m.PagePermissionRoutingModule),
  },

  {
    path: 'alert',
    loadChildren: () =>
      import('./components/alert/alert.module').then((m) => m.AlertModule),
  },

  {
    path: 'manage-device',
    loadChildren: () =>
      import('./components/create-database/create-database.module').then(
        (m) => m.CreateDatabaseModule,
      ),
  },

  {
    path: 'feeder-list',
    loadChildren: () =>
      import('./components/feeder/feeder.module').then((m) => m.FeederModule),
  },

  {
    path: 'column-permission',
    loadChildren: () =>
      import('./components/column-permission/column-permission.module').then(
        (m) => m.ColumnPermissionModule,
      ),
  },

  {
    path: 'assign-hierarchical-structure',
    loadChildren: () =>
      import(
        './components/assign-hierarchical-structure/assign-hierarchical-structure.module'
      ).then((m) => m.AssignHierarchicalStructureModule),
  },

  {
    path: 'complaints',
    loadChildren: () =>
      import('./components/complaints/complaints.module').then(
        (m) => m.ComplaintsModule,
      ),
  },

  {
    path: 'substation',
    loadChildren: () =>
      import('./components/substation/substation.module').then(
        (m) => m.SubstationModule,
      ),
  },

  {
    path: 'module-history',
    loadChildren: () =>
      import('./components/module-history/module-history.module').then(
        (m) => m.ModuleHistoryModule,
      ),
  },
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
